<template>
  <div class="portfolio-detail__page">
    <div class="portfolio-wrapper">
      <div class="portfolio-header">
        <Header />
      </div>
      <div class="portfolio-deatil">
        <PortfolioDetail />
      </div>
    </div>
    <div class="portfolio-deatil__footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { Header, PortfolioDetail, Footer } from '@/components'

export default {
  components: {
    PortfolioDetail,
    Header,
    Footer
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.portfolio-detail__page {
  .portfolio-wrapper {
    min-height: calc(73vh);
    @include larger-screens {
      min-height: calc(80vh);
    }
  }
  .portfolio-header {
    position: fixed;
    top: 0;
    width: 100%;
    background: $primary-white;
    z-index: 1;
  }
  .portfolio-deatil {
    margin-top: 101px;
  }
}
</style>
